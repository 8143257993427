import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const MleIcon = () => (
  <SvgIcon viewBox="0 0 512 512">
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      // fill="#000000"
      stroke="none"
    >
      <path
        d="M3324 4210 c-377 -99 -703 -576 -829 -1215 -20 -106 -28 -166 -51
-405 l-5 -55 -144 -141 c-159 -156 -227 -202 -251 -169 -15 21 -13 38 31 210
74 285 99 485 76 597 -18 87 -72 155 -153 197 -59 30 -78 35 -165 39 -174 8
-314 -44 -437 -162 l-64 -61 -5 58 c-13 147 -158 267 -334 275 -135 6 -236
-34 -332 -132 l-45 -46 -29 58 c-106 207 -225 246 -328 108 -50 -66 -132 -232
-168 -341 -67 -204 -84 -355 -85 -760 -1 -296 0 -324 19 -374 26 -70 60 -96
123 -95 88 3 285 105 323 168 26 42 24 103 -7 211 -18 63 -27 125 -31 205 -5
106 -3 121 21 195 55 162 180 369 258 425 68 50 137 35 156 -33 6 -22 2 -114
-11 -272 -12 -149 -20 -339 -21 -500 -1 -239 1 -264 19 -309 43 -106 112 -117
267 -44 156 74 198 116 198 195 0 21 -14 92 -30 157 -37 149 -38 218 -5 317
74 218 215 409 301 409 42 0 85 -22 100 -53 20 -37 15 -93 -23 -312 -37 -207
-39 -435 -5 -542 24 -77 64 -133 113 -159 34 -17 155 -19 231 -3 79 16 207 81
290 148 79 63 107 92 160 162 l34 46 35 -66 c71 -139 228 -251 397 -286 95
-19 280 -20 355 0 104 27 229 99 321 185 l49 45 15 -25 c33 -53 139 -147 194
-173 104 -49 174 -60 368 -54 191 5 272 20 419 77 221 86 407 274 465 469 21
72 21 184 0 205 -29 29 -52 17 -146 -77 -187 -187 -328 -270 -510 -298 -132
-20 -289 24 -344 96 -24 32 -29 49 -32 110 l-4 72 78 33 c44 18 122 48 174 67
52 19 142 57 200 85 133 65 234 159 268 251 32 83 31 213 -2 282 -73 159 -275
220 -520 158 -248 -62 -470 -272 -585 -555 -42 -102 -76 -229 -87 -320 -6 -52
-11 -62 -55 -105 -103 -100 -244 -163 -365 -163 -78 0 -126 19 -178 68 -63 61
-78 107 -84 259 l-4 132 45 58 c66 86 174 209 339 388 256 279 374 432 443
576 44 92 58 163 53 264 -7 134 -67 215 -184 251 -65 19 -194 17 -280 -6z
m102 -325 c22 -33 12 -95 -26 -172 -16 -32 -99 -146 -185 -253 -86 -107 -177
-226 -203 -263 -26 -38 -48 -64 -50 -59 -4 14 42 191 76 292 74 216 199 412
294 459 56 27 74 27 94 -4z m959 -820 c31 -30 31 -56 4 -112 -23 -48 -71 -88
-206 -172 -93 -58 -93 -58 -67 48 31 124 93 229 148 251 43 18 96 11 121 -15z"
      />
    </g>
  </SvgIcon>
)
